<template>
	<b-container fluid>
		<b-row>
			<b-col sm="12">
				<card>
					<template v-slot:headerTitle>
						<h4 class="card-title">Files of ({{student.name}})</h4>
					</template>
					<template v-slot:headerAction>
						<div class="float-right">
							<router-link :to="{ name: 'students' }" class="btn btn-primary mt-2 mr-1 btn-sm"><i class="fas fa-angle-double-left"></i> Back</router-link>
							<b-button v-b-modal.file-modal variant="primary" class="btn-sm mt-2 mr-1">Add File</b-button>							
						</div>
					</template>
					<template v-slot:body>						

						<!--- Listing starts ----->
						<div class="table-responsive">
							<table id="datatable" class="table data-table table-striped table-bordered dataTable">
								<thead>
									<tr>
										<th>File Name</th>	
										<th>File</th>
										<th>Uploaded on</th>
										<th>Options</th>
									</tr>
								</thead>
								<tbody v-if="Boolean(files.length)">
									<tr v-for="file in files" :key="file.id">
										<td>{{ file.file_name }}</td>
										<td><a :href="$image_url + file.file" target="_blank"><i class="fas fa-file-pdf" style="font-size: 25px;"></i></a></td>
										
										<td>{{file.created_at | formatDateTime}}</td>
										<td>
											<b-button variant="primary" class="btn btn-sm mt-2 mr-1" @click="editFile(file)">Edit</b-button>
											<b-button variant="danger" class="btn mt-2 mr-1 btn-sm" @click="deleteFile(file.id)">Delete</b-button>
										</td>
									</tr>				
							  </tbody>
							  <tbody v-else>
									<tr>
										<td colspan="4" class="text-center">No files uploaded</td>
									</tr>
								</tbody>	
							</table>
						</div>
						<!-- Listing ends -->
						<!---- modal starts ---->
						<b-modal ref="file-modal" id="file-modal" :title="modal_title" hide-footer @hidden="clearForm()">
							<b-form @submit.prevent="addFile" enctype="multipart/form-data">
								
								<b-col md="12" class="form-group">
									<label class="control-label">File Name<span class="required">*</span></label>
									<b-form-input v-model="file.file_name" v-bind:class="{ 'is-invalid': Boolean(errors.file_name) }"></b-form-input>
									<div v-for="(val, index) in errors.file_name" :key="index" class="invalid-feedback">
										<p class="mb-0">{{ val }}</p>
									</div>
								</b-col>
                                
								<b-col md="12" class="form-group" v-if="!edit">
									<label class="control-label">File<span class="required">*</span> <span class="note">[Allowed Formats: pdf]</span></label>
									
									<input type="file" id="fileinput" @change="setFile" accept="pdf/*" class="form-control image-class" v-bind:class="{ 'is-invalid': Boolean(errors.document) }" />
									<div v-for="(val, index) in errors.document" :key="index" class="invalid-feedback">
										<p class="mb-0">{{ val }}</p>
									</div>				
								</b-col>					
								
								<b-col md="12">
									<b-button type="submit" class="mr-3" variant="primary">{{ button_title }}</b-button>
									<b-button class="" variant="light" @click="hideModal('file-modal')">Close</b-button>
								</b-col>
							</b-form>
						</b-modal>
						<!---- modal ends ----->
						
					</template>
				</card>
			</b-col>
		</b-row>
	</b-container>
</template>
<script>
export default {
	name: "StudentFiles",
	data() {
		return {
			files: [],
			student : {},
			file: {
				id: null,
			   	file_name: "",
			   	document: "",
			},
			
			modal_title: "Add File",
			button_title: "Save",
			edit: false,			
			token: "",
			errors: {},			

		};
	},
	mounted() {
			
	},

	created() {
		this.fetchFiles();
		this.fetchStudent();
	},

		methods: {
		    
		    fetchFiles(page_url = null) {
		    	this.fetchStudent();
				let url = "/api/student_documents?student_id=" + this.$route.params.id;
				page_url = page_url || url;

				this.getToken();
				this.$axios
					.get(page_url, { headers: { Authorization: "Bearer " + this.token } })
					.then((res) => {
						var responseData = res.data.data;
						this.files = responseData.documents;
						// this.student   = responseData.student;
						this.pagination = responseData.links;
						console.log(this.files);
					})
					.catch((err) => console.log(err));
			},
            
            fetchStudent() {
		          let url = '/api/students/details?student_id=' + this.$route.params.id ;
		            
		         this.getToken();
		         this.$axios
		            .get(url, {headers: {Authorization: 'Bearer ' + this.token,}})
		            .then(res => {
		               var responseData = res.data.data;               
		               this.student = responseData.student; 
		               console.log(this.student);             
		            })
		            .catch(err => console.log(err));
		      }, 

			getToken() {
				if (localStorage.token) {
					this.token = localStorage.token;
				} else {
					this.$vToastify.error("Session expired, please login again");
					this.$router.push({ name: "auth.login" });
				}
			},

			showModal(modal) {
				this.$refs[modal].show();
			},

			hideModal(modal) {
				this.$refs[modal].hide();
			},
            
            setFile(e) {
				this.document = e.target.files[0];
				console.log(this.document);
			},

            addFile() {
				this.getToken();
				let formData = new FormData();
				formData.append("file_name", this.file.file_name);
				formData.append("document", this.document);
				formData.append("student_id", this.$route.params.id);
				// alert(this.document);
				const config = {
					headers: {
						"content-type": "multipart/form-data",
						Authorization: "Bearer " + this.token,
					},
				};
				let url;
				if (!this.edit) {
					url = "/api/student_documents/add";
					this.active_page_url = null;
				} else {
					url = "/api/student_documents/update";
					formData.append("file_id", this.file.id);
				}
				this.$axios
					.post(url, formData, config)
					.then((response) => {
						if (response.data.status) {
							this.$vToastify.success(response.data.message);
						} else {
							this.$vToastify.error(response.data.message);
						}
						this.clearForm();
						this.hideModal("file-modal");
						this.fetchFiles(this.active_page_url);
					})
					.catch((error) => {
						console.log(error.response.data);

						if (error.response) {
							if (error.response.data.message) {
								this.errors = error.response.data.message;
								this.$vToastify.error(error.response.message);
							} else {
								this.$vToastify.error("Error!");
							}
						} else {
							this.$vToastify.error("Error!");
						}
					});
			},

			editFile(file) {	
				this.edit = true;
				this.file.id = file.id;
				this.file.file_name = file.file_name;
				this.errors = {};
				this.modal_title = "Edit File";
				this.button_title = "Update";
				this.file.name = "School Certificate";
				this.showModal("file-modal");
			},
			clearForm() {				
				this.edit = false;
				this.modal_title = "Add File";
				this.button_title = "Add";
				this.file.name = "";
			},
			
            deleteFile(file_id) {
	         this.delete = false;
	         this.$bvModal.msgBoxConfirm('Are you sure you want to delete this file?', {
	            title: 'Confirm',
	            size: 'sm',
	            buttonSize: 'sm',
	            okVariant: 'danger',
	            okTitle: 'Yes',
	            cancelTitle: 'No',
	            footerClass: 'p-2',
	            hideHeaderClose: false,
	            centered: true
	         })
	         .then(value => {
	            this.delete = value
	            if(this.delete){
	               this.getToken();
	               this.$axios
	                  .get("/api/student_documents/delete?student_document_id=" + file_id,{headers: {Authorization: 'Bearer ' + this.token,}})
	                  .then(response => {
	                     if(response.data.status) {
	                           this.$vToastify.success(response.data.message);
	                     } else {
	                           this.$vToastify.error(response.data.message);
	                     }	                     
	                     this.fetchFiles();
	                  })
	                  .catch(err => console.log(err));
	            }
	         })
	         .catch(err => {
	            console.log(err);
	         })         
	      },
		},	
};
</script>
